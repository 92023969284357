<template>
	<div class="mpaisFormHeader puiformheader">
		<v-row>
			<v-col cols="12">
				<pui-field :label="$t('header.mpais.idpais')" :value="getIdpaisValue"></pui-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<pui-field :label="$t('header.mpais.codlocode')" :value="getCodlocodeValue"></pui-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<pui-field :label="$t('header.mpais.escomunitario')" :value="getEscomunitarioValue"></pui-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<pui-field :label="$t('header.mpais.name')" :value="getNameValue"></pui-field>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'mpais-form-header',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'mpais'
		};
	},
	computed: {
		getIdpaisValue() {
			return this.model && this.model.idpais ? this.model.idpais : '-';
		},
		getCodlocodeValue() {
			return this.model && this.model.codlocode ? this.model.codlocode : '-';
		},
		getEscomunitarioValue() {
			return this.model && this.model.escomunitario ? this.model.escomunitario : '-';
		},
		getNameValue() {
			return this.model && this.model.name ? this.model.name : '-';
		},
	}
}
</script>
